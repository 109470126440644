@import '@theme';

  // reset message 的样式 写到 message.scss，原因：为了拷贝至旧项目

// reset antd table
.ant-table {
    table {
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 0 !important;
    }
    thead {
        th {
            height: $btn-height-base;
            line-height: $btn-height-base;
            padding: 0 15px !important;
            color: #999 !important;
            font-size: $font-size-base;
            background: #FFF !important;
        }
    }
    td {
        height: $btn-height-base;
        line-height: $btn-height-base;
        padding: 0 0 !important;
        font-size: $font-size-base;
    }
    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
        display: none !important;
        color: #999 !important;
    }
    .ant-table-column-sorter-up.on,
    .ant-table-column-sorter-down.on {
        display: block !important;
    }
    .ant-table-body {
        overflow-x: auto !important;
    }
    .ant-table-column-title {
        text-overflow:ellipsis; 
        overflow: hidden;
        white-space:nowrap; 
    }
}

// reset ant modal
.ant-modal {
    .ant-modal-footer {
        padding: 20px 0;
        border-top: none;
        text-align: center;
     }
     .ant-modal-footer .ant-btn {
        min-width: 70px;
        border-radius: 32px;
        font-size: 12px;
     }
     .ant-modal-title {
        color: $primary-color;
    }
}

// reset antd form
.ant-form-item {
    margin-bottom: 5px;
}
.ant-input-search {
    .ant-input {
        height: 32px;
        line-height: 32px;
    }
}
.ant-form {
    .ant-calendar-picker-icon {
        color: $primary-color;
    }
}
// label 必填 标志
.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-item-required::before {
    display: none !important;
  }
  .ant-form-item-required::after {
    display: inline-block !important;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
  }

// 去除input聚焦投影
.ant-input:focus {
    box-shadow: none;
}

// reset menu
.ant-menu {
    background: none;
}
.ant-menu-submenu > .ant-menu {
    background: none;
}
.ant-menu-item {
    width: 100% !important;
}
.ant-menu-item-selected {
    font-weight: bold;
}
.ant-menu-item::after {
    right: calc(100% - 3px) !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #FFF;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  
.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }