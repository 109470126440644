@import '@theme';
@import './../base.scss';

.wrap {
    display: flex;
    height: 100%;
}
// antd menu ul
.wrap ul { 
    border-right: 0 !important;
}
.sider { 
    position: relative;
    width: 200px;
    border-right: solid 1px $border-color-base;
    background: #F7F8FA;
}
.closesider {
    width: 20px;
}
.closesider h1,
.closesider ul {
    display: none;
}
.appname {
    height: $bar-base-height;
    line-height: $bar-base-height;
    margin-bottom: 0;
    font-size: 16px;
    padding:0 16px;
    font-weight: bold;
    text-indent: 8px;
}

.shrink {
    display: flex;
    position: absolute;
    top: 10px;
    left: 186px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: solid 1px $primary-color;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    z-index: 9;
    background: #FFF;
    span {
        font-size: 20px;
    }
    &:hover {
        background: $primary-color;
        span { color: #FFF; }
    }
}

.shrinkhide {
    left: 5px;
    transform:rotate(180deg);
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #FFF;
    overflow-x: hidden;
}
.menu {
    margin: 0;
    padding: 0;
}

.on {
    position: relative;
    background: #FFF;
    color: $primary-color;
    font-weight: 700;
}
.on::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background: $primary-color;
    content: '';
}

.submenu_icon {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.submenu_title {
    margin-left: 10px;
    color: #666;
    font-weight: bold;
}