@import '@theme';

@font-face {
    font-family: 'welinkin';  /* project id 1378845 */
    src: url('/navbarx/iconfont/iconfont.eot');
    src: url('/navbarx/iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
    url('/navbarx/iconfont/iconfont.woff2') format('woff2'),
    url('/navbarx/iconfont/iconfont.woff') format('woff'),
    url('/navbarx/iconfont/iconfont.ttf') format('truetype'),
    url('/navbarx/iconfont/iconfont.svg#welinkin') format('svg');
  }
  
.icon {
    font-family: welinkin;
}
.success {
    color: $primary-color;
}
.error {
    color: $error-color;
}
.cursor {
    cursor: pointer;
}