@import '@theme';

html,body,#root {
    height: 100%;
}

// reset ant modal
.ant-modal {
    .ant-modal-footer {
        padding: 20px 0;
        border-top: none;
        text-align: center;
     }
     .ant-modal-footer .ant-btn {
        min-width: 70px;
        border-radius: 32px;
        font-size: 12px;
     }
     .ant-modal-title {
        color: $primary-color;
    }
}